@media (max-width: 767px) {
  html {
    margin: 0 !important;
    padding: 0 !important;
    scroll-behavior: smooth !important;
  overflow-x: hidden;
  
  }
  .jGTbPl {
    font-size: 10px;
    font-weight: 400;
    color: rgb(58, 58, 58);
  }
  .mob-hide{
    display: none !important;
  }
  .mobile{
    display: block !important;
  }
  .navbar li {
    display: none;
  }
  .mttop{
    width: 90%;
  }
  .enquiry-content{
    margin-top: 12px !important;
  }
  /* ======================corporate=============== */
  .dwReTq {
    flex-direction: column !important;
    width: 100% !important;
}
.foUrhR {
 flex-direction: column !important;
  
  align-items: flex-start;
  -webkit-box-pack: justify;
  
  height: 100%;
  width: 100%;
}

.lgQrCi {
  padding: 70px 16px 0px !important;
}
.GQyBR {
  gap: 50px;
}
.kbkTjD {
  font-size: 39.81px;
  font-style: normal;
  font-weight: 400;
  line-height: 47.772px;
}
.eBgksK {
  width: 91.8vw !important;
  aspect-ratio: 358 / 250;
  flex-shrink: 0;
  margin-top: 40px;
}
.hYjroz {
  margin-top: 10px;
  color: rgba(12, 12, 12, 0.85);
  
  font-size: 15.75px;
 
  font-weight: 500;
  line-height: 20.475px;
}
.iiqXBP {
  color: rgba(12, 12, 12, 0.8);
  
  font-size: 15.75px;
 
  font-weight: 400;
  line-height: 23.6px;
}

  .content p {
    color: white !important;
  }
 
  .tour-enquiry-card .tour-expereince-question {
    font-size: 15px;
  }

  .section__intro__main.intro-heading .intro-heading__search-wrap .search-wrap__tags-bar .tags-bar__tag {
    background: transparent;
    padding: 0;
    border: none;
    font-size: 1rem !important;
    letter-spacing: -0.65px;
    display: flex;
    align-items: center;
    
    transition: 0.2s all;
    padding: 5px 10px !important;
  }

  .dfBLcg {
    height: 54px;
    width: 350px !important;
    margin-left: auto;
    margin-right: auto;

  }

  .tour-enquiry-card {
    grid-template-columns: 0.5fr 2fr;
    margin: 30px 15px !important;
  }

  .tour-hidden-utility {
    height: 195px;
  }

  .tour-enquiry-card .tour-expereince {
    font-size: 11px;
  }

  .tour-enquiry-card .tour-enquiry-call {
    margin: 10px;
    grid-column: 1/3;
  }

  .tour-enquiry-card .enquiry-image {
    width: 100px;
    height: 100px;
  }

  .nearby-destinations__destinations-bar-item {
    border: 1.5px solid #949494;
    color: #949494;
    font-weight: 500;
    font-size: 0.8rem !important;
    line-height: 1.5;   
    align-items: center;
    
   
    transition: 0.2s color, 0.2s border-color;
  }

  .nearby-destinations__destinations-bar {
    overflow: hidden !important;
  }
  /* ====================destination header =========================== */
  .banner-container {
    padding-left: 15px;
    align-items: center;
    text-align: left;
    justify-content: flex-end;
    padding-bottom: 8px;
   
 
    position: absolute;
  }
  
  .banner-main-heading {
    display: inline-block;
    color: #fff;
    font-size: 25px;  
    
    font-weight: 600;
    line-height: 25px;
}

  .banner-sub-heading {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    margin-top: 15px;
    justify-content: center;
  }
  
  .banner-main-heading {
    margin-top: 2.5px;
  }
  .inner-shadow::before {
   border-radius: 0px !important;
  }
  
  .banner-divider {
    min-width: calc(100vw - 30px);
  }
  
  .banner-price-wrapper {
 
    margin-top: 0;
  }
  
  .banner-price-label {
    color: #e0e0e0;
    font-size: 11px;
    line-height: 17px;
  }
  
  .banner-actual-price {
    font-size: 16px;
    line-height: 24px;
    margin: 0 5px 0 0;
  }
  
  .banner-strike-through-price {
    color: #bfbfbf;
    font-size: 14px;
    line-height: 21px;
  }
  
  .banner-main-button {
    display: none;
  }
  .wraper{
    padding: 0 2% ;
  }
  /* =====================grid-gal========================== */
  .grid-gal{
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    grid-auto-rows: 250px;
  }
  .grid-gal .grid-item:nth-child(3n-2){
    grid-column: unset !important;
    grid-row: unset !important;
  }
  /* =========================card arrow==================== */
  .LaSZC{
    display: none;
  }
 
/* =======================================================whatsapp form======================= */
.gzcIkF {
  flex-direction: column !important;
  width: 100vw !important;
  height: 700px !important;
  margin-top: 30px !important;
  padding-top: 20px !important;
  border-radius: 15px 15px 0px 0px ;
}
.dWaxKu {
  height: 24px;
  width: 24px;
  
  top: 25px !important;
  right: 20px !important;
}
.hgEiju {
  display: none  !important;
}
.boVvJR {
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;
  flex-direction: column-reverse !important;
}
.fgWgyq {
  height: 48px !important;
  font-family: Roboto;
  font-weight: 500;
  font-size: 15.75px;
  line-height: 20px;
  margin-top: 10px;
}

.jYPSRL {
  width: 90% !important;
  margin: 20px 0px;
  align-self: center !important;
  height: 100% !important;
  padding: 15px !important;
  background-color: rgba(1, 175, 209, 0.15);
  border: 1px solid rgb(1, 95, 116);
  border-radius: 8px !important;
}
.iDMjwz {
 
  font-weight: 700 !important;
  font-size: 22.43px !important;
  line-height: 29px !important;
  padding: 0px 0px 8px !important;
}
.hpfaQN {

  font-weight: 500;
  font-size: 15.75px !important;
  line-height: 20px !important;
}
.kpOaoh {
  display: flex;
  padding-bottom: 16px;
}
.fhucTq {
  color: rgb(1, 95, 116);
}
.gUJNjz {
  left: 2vw;
}
.eQRUfz {
  height: 44px;
}
  /* ===============itinary details===================== */

  .bObLtH {
    width: 25vw !important;
    height: 9vw;
    font-size: 2.7vw;
  }

  .jDDHOQ {
    width: 25vw !important;
    height: 8vw;
    font-size: 2.7vw;
  }

  .gtBFzr {
    font-size: 3vw !important;
    line-height: 3vw;
  }

  .biHDcv {
    height: 10vw;
    width: 10vw;
    padding: 2vw;
  }

  .qzbwN {
    gap: 6px;
    -webkit-box-align: center;
    align-items: center;
  }

  .cVvfvW {
    width: 80vw;
    gap: 16px;
    margin: 25px 0px;
  }

  .QnrIb {
    font-size: 12vw;
  }

  .dsfIqt {
    display: flex !important;
    flex-direction: column !important;
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .dFnMVc {
    width: 250px;
    text-decoration: none;
    height: 42px;

    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    color: rgb(58, 58, 58);
    background-color: rgba(58, 58, 58, 0.2);
    border-radius: 0.3vw;
    margin-right: 10px;
  }


  .dFnMVcc {
    width: 250px;
    text-decoration: none;
    height: 42px;

    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    color: rgb(58, 58, 58);
    background-color: rgba(58, 58, 58, 0.2);
    border-radius: 0.3vw;
    margin-right: 10px;
  }

  .info-btn-it {
    margin-top: 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    overflow: auto;
    justify-content: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  /* ===================================it days======================================= */
  .dmcNqzinfo {
    font-size: 20px;
    width: 100%;

  }
/* ===============================Castomer care care-card=================================== */
.reach-out .bgwhite .inboubtsbox {
  padding-right: 25px!important;
}
.reach-out .bgwhite .title {
  font-size: 12px;
}
.reach-out .bgwhite .t-content {
  font-size: 10px;
}
.reach-out {
  height: 250px;
}
.h-100 {
  height: 100%!important;
}
.reach-out .bgwhite {
  height: 200px;
  width: 90%;
}
.reach-out .bgwhite a.reachus {
  font-size: 10px;
  font-weight: 600;
  margin: 5px auto;
  padding: 5px;
  text-align: center;
  width: 100%;
}
.reach-out .bgwhite .inboubtsbox {
  padding-right: 25px!important;
}
  /* ==========================first intro================= */
  .bXBxlH {
    aspect-ratio: 358 / 150;
    margin-top: 0px;
    background-image: url(https://images.wanderon.in/phone%20banners/phone%20banners/bhutan_phone);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .idQJkO {
    padding: 0px 16px !important;
  }
}

@media screen and (max-width: 919px) {
  .links li a {
    display: none;
  }
  .navbar li {
    display: none;
  }
  .hidden-bar{
    display: block !important ;
  }
  

  nav h5 {
    display: none;
  }

  .section__intro__main.intro-heading .intro-heading__search-wrap .search-wrap__tags-bar .tags-bar__tag {
    background: transparent;
    padding: 0;
    border: none;
    font-size: 1.2rem;
    letter-spacing: -0.65px;
    display: flex;
    align-items: center;
    
    transition: 0.2s all;
    padding: 5px 15px;
  }

  .dfBLcg {

    display: block;
    height: 50px;
    width: 400px;

  }

  .ibbWOl {
    padding: 17px 29px;
    border-radius: 6px;
    font-size: 15px;
    line-height: 20px;
  }
}